.tile_box {
  display: flex;
  flex-direction: column;
  padding: 1em;
  margin: 1em;
  border: white 1px solid;
  border-radius: 1em;
  box-shadow: 0 0 13em #ffffff22;
  width: 300px;
}

.tile_box:hover {
  transform: scale(1.05);
  transition-duration: 300ms;
}

.tile_box h2 {
  font-size: 1.5em;
  margin: 0.5em 0;
  color: rgb(228, 221, 221);
}

.tile_box p {
  font-size: 1em;
  font-weight: 500;
  margin: 0.5em 0;
  color: #bdb9b9;
  max-width: fit-content;
}

.tile_text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0.5em 0;
}

.dashboard_tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dashboard_navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1em;
}

.tile_article:hover {
  transform: scale(1.05);
}

.tile_img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 1em;
}

h3.price{
  color: #fff;
}

.navbar {
  background-color: #dedede;
  color: white;
  /* display: flex;
  justify-content: space-between; */
  align-items: center;
  border-radius: 0 0 1em 1em;
  width: 100%;
}

.navbar-head {
  font-size: 24px;
  font-weight: bold;
  padding: 0 1em;
  text-align: center;
}

.navbar-head img {
  width: 15vh
}

.navbar-elements {
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  gap: 1em;
  padding-right: 0.5em;
  font-weight: 500;
}
.nav-el:hover {
  transform: rotate(5deg);
  transition-duration: 100ms;
}

.navbar-links li {
  margin: 0;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
}

.navbar-links a:hover {
  color: #ddd;
}

.search {
  display: flex;
  gap: 0.3em;
  padding-right: 10px;
}

.searchbox {
  padding: 0.5em;
  border: none;
  border-radius: 0.5em;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: aliceblue;
}

.article-image{
  /* float: left; */
  max-width: 60%;
  margin:0 1em 0 0 ;
  border-radius: 5px;

}

.article-container{
  margin: 3em;
}

.article-container h1{
  color: #ffffff;
  /* border-bottom: solid 1px #ffffff; */
  /* border-radius: 5px; */
  padding-bottom: 1%;
}

.article-container p{
  color: #fff;
  font-size: 1.5em;
  line-height: 1.5;
  /* margin: 1em;  */
}

.article-container h4{
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  color: #968b8b;
  font-size: 1em;
  line-height: 1.3;
  font-family:monospace ;
}

.article-container a{
  color: #1e1d33;
  text-decoration: underline;
  background-color: rgb(172, 159, 159);
  padding: 0.5em;
  border-radius: 5px;
  max-width: fit-content;
  font-weight: 500;
}

a:link{
  color:black;
  text-decoration: none;
}
a:active{
  color:black;
  text-decoration: none;
}
a:visited {
  color: black;
}